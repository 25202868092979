<template>
<div>
  <Form :model="formData">
    <FormItem>
      <Input v-model="formData.url" type="textarea" :rows="35" placeholder="url资源, 以,分隔" />
    </FormItem>
    <FormItem>
      <Button type="primary" @click="submit" long>更新资源</Button>
    </FormItem>
  </Form>
</div>
</template>

<script>
import {put, get, del} from "@/api/http";
import Notice from "@/components/baseNotice";
export default {
  name: "Urlsource",
  data() {
    return {
      formData: {
        url: ""
      }
    }
  },
  methods: {
    getUrls() {
      get("/api/v1/urlsource")
      .then((resp)=>{
        const code = resp.code
        if (code === 0) {
          if (resp.data.length > 0) {
            this.formData.url = resp.data[0].url;
          }
        }
      })
    },
    submit(){
      this.$Modal.confirm({
        title: '确认更新资源吗？',
        // content: '确认更新资源吗？',
        onOk: () => {
          put("/api/v1/urlsource", this.formData)
              .then((resp)=>{
                const code = resp.code
                if (code === 0) {
                  this.getUrls()
                  Notice.success("更新操作", "更新成功")
                } else {
                  Notice.error("更新操作", resp.data.msg)
                }
              })
        }
      })
    }
  },
  created() {
    this.getUrls()
  }
}
</script>

<style scoped>

</style>